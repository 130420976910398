/* eslint-disable no-unused-vars */
import {Utils} from '@busuu/legacy-core';

// UI Classnames
const UI_DISABLED_CLASSNAME = 'claim-trial--disabled';

class FreeTrialBannerBase {
    constructor(plan, options = {}) {
        this.options = options;

        this.trialBanner = Utils.getById('claim-trial-banner');
        this.trialContent = Utils.getElementByClass('js-trial-duration', this.trialBanner);
        this.trialCTA = Utils.getElementByClass('js-trial-plan', this.trialBanner);

        this.onInit = this.onInit.bind(this);
        this.onClickCTA = this.onClickCTA.bind(this);
        this.updateContent = this.updateContent.bind(this);
        this.show = this.show.bind(this);
        this.disable = this.disable.bind(this);

        if (this.trialBanner) {
            this.trialCTA.addEventListener('click', this.onClickCTA);

            this.onInit(plan);
        }
    }

    /**
     * Fired upon init of the banner
     * @param {Object} plan
     * @abstract
     */
    onInit(plan) {}

    /**
     * Fired upon clicking the CTA
     * @param {Event} e
     */
    onClickCTA(e) {
        if (e) {
            e.preventDefault();
        }

        const {onClickCTA} = this.options;

        this.disable();

        onClickCTA();
    }

    /**
     * Update the current HTML content of the banner
     * @param {string} content
     * @public
     */
    updateContent(content) {
        if (this.trialContent) {
            this.trialContent.innerHTML = content;
        }
    }

    /**
     * Remove hidden class from trial banner
     * @public
     */
    show() {
        Utils.removeClass(this.trialBanner, 'hidden');
    }

    /**
     * Add hidden class to trial banner
     * @public
     */
    hide() {
        Utils.addClass(this.trialBanner, 'hidden');
    }

    /**
     * Add disabled class to trial banner
     * @public
     */
    disable() {
        Utils.addClass(this.trialBanner, UI_DISABLED_CLASSNAME);
    }
}

export default FreeTrialBannerBase;
