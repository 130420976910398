import isEmpty from 'lodash/isEmpty';
import {Utils, ScriptSnowplow as SnowplowScript, ExceptionService, ThirdPartyService} from '@busuu/legacy-core';
import Optimizely from 'tracking/tracking-optimizely.js';

/*
 * Private vars
 */
const filename = 'tracking-snowplow.js';
const commonData = {};
let enableLogs = false;
let userData = null;

const isVendorDisabled = () => {
    return ThirdPartyService.isVendorDisabled();
};

/**
 * Checks if Snowplow is present on window object
 * @return {Boolean}
 */
const isSnowplowPresent = () => {
    if (!window.snowplow) {
        ExceptionService.handle('warning', {
            filename,
            message: 'Snowplow is not found on the window.',
        });
    }
    return Boolean(window.snowplow);
};

/**
 * Prevents tracking if user is on a localhost environment.
 * @return {Boolean}
 */
const preventTracking = (eventName, data = {}) => {
    if (enableLogs) {
        ExceptionService.handle('warning', {
            filename,
            message: `TRACKING SNOWPLOW: ${eventName}`,
            data: data.params,
        });
    }
    return enableLogs ? false : Utils.isLocalEnvironment();
};

/**
 * Returns user agent string
 * Returns empty string if not found as API doesn't support null value
 * @return {String}
 */
const getUserAgent = () => {
    if (!window.navigator || !window.navigator.userAgent) {
        return '';
    }
    return window.navigator.userAgent;
};

/**
 * Gets the current timestamp and returns it as a 10 digit epoch
 * @return {Integer}
 */
const getTimestamp = () => {
    const newDate = new Date();
    return Math.floor(newDate.getTime() / 1000);
};

/**
 * Returns the current user ID as a string (Integer breaks the API)
 * Returns empty string if not found as API doesn't support null value
 * @return {String}
 */
const getUserId = () => {
    if (!userData || !userData.uid) {
        return '';
    }
    return userData.uid.toString();
};

/**
 * Gets the current user role status.
 * If he has an institution he's part of PRO, so we return b2b
 * @return {String}
 */
const getUserRole = () => {
    let userRole = userData && userData.is_premium ? 'premium' : 'free';

    if (userData && userData.institution) {
        userRole = 'b2b';
    }

    return userRole;
};

/**
 * Gets the current user country
 * @return {String}
 */
const getUserCountry = () => {
    return userData && userData.country_code ? userData.country_code : '';
};

/**
 * Gets the current user interface language
 * @return {String}
 */
const getInterfaceLanguage = () => {
    if (!userData || !userData.languages || !userData.languages.interface) {
        return window.BUSUU_GLOBALS.LANG_INTERFACE || '';
    }
    return userData.languages.interface;
};

/**
 * Gets the current user course language
 * @return {String}
 */
const getCourseLanguage = () => {
    if (!userData || !userData.languages || !userData.languages.learning_default) {
        return '';
    }
    return userData.languages.learning_default;
};

/**
 * Builds event data (name, timestamp & params)
 * @param {String} eventName
 * @param {Object} data
 * @return {Object}
 */
const getSnowplowEventData = (eventName, data = {}) => {
    let params = {...commonData, ...data};

    /**
     * Snowplow tracker uses Optimizely behind the scenes.
     * We only extend our data to send optimizely data if
     * we find optimizely on the window and have experiment data to send.
     */
    if (Optimizely.isLoaded()) {
        // Get visitor's id to be able to track the same user's behavior before and after authentication
        const optimizelyVisitorId = Optimizely.getVisitorId();
        if (optimizelyVisitorId) {
            params = {...params, optimizelyVisitorId};
        }

        const optimizelyExperimentData = Optimizely.getExperimentsData();
        if (!isEmpty(optimizelyExperimentData)) {
            params = {
                ...params,
                optimizely_experiments_current: optimizelyExperimentData,
            };
        }
    }

    params = Utils.deepClone(params);

    return {
        event: eventName,
        ts: getTimestamp(),
        params,
    };
};

/**
 * Initialises all platform data
 * to a snowplow event following the schema defined here:
 * https://busuucom.atlassian.net/wiki/display/AN/Snowplow+Events+Endpoint
 * User agent sniffing is considered bad practise if we use it for features
 * (https://css-tricks.com/browser-detection-is-bad/)
 * but in this case, for now, we just need to pass through the string if the browser supports it
 * Snowplow is using a User Agent parser enricher once the data is received the parse the `user_agent` string
 * https://github.com/tobie/ua-parser
 * @return {Object}
 */
const getSnowplowPlatformData = () => {
    return {
        platform: 'web',
        version: process.env.version,
        user_agent: getUserAgent(),
    };
};

/**
 * Returns all required snowplow user data
 * @return {Object}
 */
const getSnowplowUserData = () => {
    return {
        uid: getUserId(),
        language_learnt: getCourseLanguage(),
        interface_language: getInterfaceLanguage(),
        role: getUserRole(),
    };
};

/**
 * Snowplow sendEvent method
 * Sends `trackSelfDescribingEvent` tracking to Snowplow
 * @param {Object} eventTemplate
 * @param {Object} data
 */
const sendEvent = (eventTemplate = {}, data = {}) => {
    const eventName = eventTemplate.real_event_name;
    if (!eventName) {
        ExceptionService.handle('error', {
            filename,
            message: 'eventName is missing',
        });
        return false;
    }

    const _data = Utils.deepClone(data);

    // Get all one-time common data
    const snowplowPlatformData = getSnowplowPlatformData();

    // Get snowplow user data
    const snowplowUserData = getSnowplowUserData();

    // Get snowplow event data
    const snowplowEventData = getSnowplowEventData(eventName, _data);

    /**
     * Builds our snowplow data object that we pass through to Snowplow following the schema defined here:
     * https://busuucom.atlassian.net/wiki/display/AN/Snowplow+Events+Endpoint
     * N.B: If the data isn't available (user is not authenticated), mandatory value
     * are passed using empty strings to follow the schema.
     */
    let snowplowData = {
        ...snowplowPlatformData,
        ...snowplowUserData,
        ...snowplowEventData,
    };

    snowplowData = Utils.deepClone(snowplowData);

    if (!isSnowplowPresent() || preventTracking(eventName, snowplowData)) {
        return false;
    }

    window.snowplow('trackSelfDescribingEvent', {
        schema: 'iglu:com.busuu/standard_event/jsonschema/1-0-0',
        data: snowplowData,
    });
};

/**
 * Store current user object to access later in custom events
 * @param {Object} user
 */
const identifyUser = (user) => {
    if (!isSnowplowPresent()) {
        return false;
    }

    userData = Utils.deepClone(user);

    /**
     * Add additional datas to the tracking when the user
     * is authenticated
     */
    commonData.country = getUserCountry();

    // Set user ID within snowplow for the remainder of the session
    if (userData.uid) {
        window.snowplow('setUserId', userData.uid);
    }
};

// Load Snowplow and get common data
const load = (params) => {
    if (params.logs) {
        enableLogs = true;
    }

    // Load Snowplow
    if (!isVendorDisabled()) {
        SnowplowScript.init('homepage');
    }
};

export default {
    sendEvent,
    identifyUser,
    load,
};
