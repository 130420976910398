import each from 'lodash/each';
import {Utils, CookiesStorageService} from '@busuu/legacy-core';
import {removeStorageItem, setStorageItem, getStorageItem, getAllKeyData} from '@busuu/browser-storage';

/**
 * This service stores data across the webapp
 * or across pages in the homepage, in most of the cases, these
 * data are used to send events or to track the user.
 */

/**
 * Remove a given key
 * @param  {string} key
 */
const remove = (key) => {
    removeStorageItem(key);
};

/**
 * Registration Onboarding
 */
const storeRegistrationOnboarding = () => {
    setStorageItem('REGISTRATION_ONBOARDING');
};

/**
 * Payment page views
 */
const storePremiumPageView = () => {
    setStorageItem('PAGE_VIEWED_PREMIUM');
};

/**
 * Payment discount
 */
const storeDiscountCoupon = (coupon) => {
    setStorageItem('DISCOUNT_COUPON', coupon);
};

const getDiscountCoupon = () => {
    return getStorageItem('DISCOUNT_COUPON');
};

const getQACaptchaToken = () => {
    return getStorageItem('CAPTCHA_TOKEN');
};

/**
 * Clear all payment stored data
 */
const clearPaymentData = () => {
    // Remove page views
    remove('PAGE_VIEWED_PREMIUM');

    // Clear discounts
    remove('DISCOUNT_COUPON');
};

const getMarketingData = () => {
    const data = getStorageItem('MARKETING_UTM');
    return data ? JSON.parse(data) : null;
};

/**
 * Retrieves a user's study plan activation data
 * @returns {object}
 */
const studyPlanGetActivationData = () => {
    const data = getStorageItem('STUDY_PLAN_ACTIVATION_DATA');
    return data ? JSON.parse(data) : null;
};

/**
 * Completely removes all activation data from localStorage
 * once a study plan has been successfully activated after a purchase.
 * NB: We also set a key that will trigger the study plan nav component to open on load
 */
const studyPlanRemoveActivationData = () => {
    setStorageItem('STUDY_PLAN_FORCE_DROPDOWN');
    removeStorageItem('STUDY_PLAN_ACTIVATION_DATA');
};

/**
 * Stores the user referral code
 * @param {Object} unique token
 */
const storeReferralUserCode = (referralCode) => {
    const string = JSON.stringify(referralCode);
    setStorageItem('REFERRAL_USER_CODE', string);
};

/**
 * Retrieves the user's unique referral code
 */
const getReferralUserCode = () => {
    const data = getStorageItem('REFERRAL_USER_CODE');
    return data ? JSON.parse(data) : null;
};

/**
 * Sets an intent for live lessons after payment.
 * This can be read in the webapp and triggers certain actions.
 * @param {Number} userId
 */
const setPostPaymentTrigger = (userId) => {
    if (!userId) {
        return false;
    }

    setStorageItem('POST_PAYMENT_TRIGGER', userId.toString());
};

/**
 * On Logout/Login/Registration, there's some keys that needs te be removed.
 * These are localStorage and sessionStorage keys.
 * Auth tokens are removed from PHP. These keys are purely product or Front-end related.
 * @param {String} type - 'login', 'logout' or 'register'
 */
const clearSpecificKeys = (type) => {
    const mapTypes = {
        logout: 'clearOnLogout',
        login: 'clearOnLogin',
        register: 'clearOnRegister',
    };
    const allkeys = getAllKeyData();
    each(allkeys, (keyData, key) => {
        if (keyData[mapTypes[type]]) {
            remove(key);
        }
    });
};

/**
 * Returns the live source item from local storage
 * @returns {string} live source
 */
const getLiveSource = () => {
    const liveSource = CookiesStorageService.getCookie('LIVE_SOURCE');
    return liveSource ? JSON.parse(liveSource) : 'unknown';
};

/**
 * Sets the live source item to local storage
 * @param {string} liveSource item key
 */
const setLiveSource = (liveSource) => {
    const formattedData = JSON.stringify(liveSource);
    CookiesStorageService.setCookie('LIVE_SOURCE', formattedData);
};

/**
 * Returns the live source params object from local storage
 * @returns {object} live source params
 */
const getLiveSourceParams = () => {
    const liveSourceParams = CookiesStorageService.getCookie('LIVE_SOURCE_PARAMS');
    return liveSourceParams ? JSON.parse(liveSourceParams) : {};
};

/**
 * Sets the live source params object to local storage
 * @param {object} liveSourceParams
 */
const setLiveSourceParams = (params = {}) => {
    const currentData = getLiveSourceParams();
    if (!Utils.isObject(currentData)) {
        return false;
    }

    const mergedParams = {...currentData, ...params};
    const formattedData = JSON.stringify(mergedParams);
    CookiesStorageService.setCookie('LIVE_SOURCE_PARAMS', formattedData);
};

/**
 * Returns whether the alternate country site banner has been dismissed
 * @returns {boolean}
 */
const getAltCountryModalDismissed = () => {
    const data = CookiesStorageService.getCookie('ALT_COUNTRY_MODAL_DISMISSED');
    return data ? JSON.parse(data) : false;
};

/**
 * Sets whether the alternate country banner is dimsissed for the next 7 days
 * @param {boolean} dismissed
 */
const setAltCountryModalDismissed = (dismissed) => {
    CookiesStorageService.setCookie('ALT_COUNTRY_BANNER_DISMISSED', dismissed.toString(), {expires: 7});
};

/**
 * Retrieves whether a user is in the streak repair flow
 * @returns {string}
 */
const getStreakRepairFlowEnteredDate = () => {
    const data = getStorageItem('STREAK_REPAIR_FLOW_ENTERED');
    return data ? JSON.parse(data) : false;
};

/**
 * Clears streak repaired flow entered constant
 */
const removeStreakRepairFlowEntered = () => {
    return remove('STREAK_REPAIR_FLOW_ENTERED');
};

/**
 * Retrieves whether a user is in the grammar review flow
 * @returns {string}
 */
const getGrammarReviewFlowViewed = () => {
    return Boolean(getStorageItem('GRAMMAR_REVIEW_FLOW_VIEWED'));
};

/**
 * Clears grammar review constant
 */
const removeGrammarReviewFlowViewed = () => {
    return remove('GRAMMAR_REVIEW_FLOW_VIEWED');
};

const SharedDataService = {
    remove,
    clearSpecificKeysAfterLogout: clearSpecificKeys.bind(null, 'logout'),
    clearSpecificKeysAfterLogin: clearSpecificKeys.bind(null, 'login'),
    clearSpecificKeysAfterRegistration: clearSpecificKeys.bind(null, 'register'),
    clearPaymentData,
    getDiscountCoupon,
    storeDiscountCoupon,
    storePremiumPageView,
    getMarketingData,
    storeRegistrationOnboarding,
    studyPlanGetActivationData,
    studyPlanRemoveActivationData,
    storeReferralUserCode,
    getReferralUserCode,
    setPostPaymentTrigger,
    getLiveSource,
    setLiveSource,
    getLiveSourceParams,
    setLiveSourceParams,
    getAltCountryModalDismissed,
    setAltCountryModalDismissed,
    getQACaptchaToken,
    getStreakRepairFlowEnteredDate,
    removeStreakRepairFlowEntered,
    getGrammarReviewFlowViewed,
    removeGrammarReviewFlowViewed,
};

export default SharedDataService;
