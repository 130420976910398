import {isInHowItWorksExp} from 'experiments/how-it-works-experiment.js';
import {isInFlagsExp} from 'experiments/flags-experiment.js';

const initHomepageExperiments = () => {
    const url = new URL(window.location.href);
    let experimentId;
    if (url.searchParams.has('exp')) return;
    if (isInHowItWorksExp() || isInFlagsExp()) {
        if (isInFlagsExp()) experimentId = 1;
        if (isInHowItWorksExp()) experimentId = 2;
        if (isInHowItWorksExp() && isInFlagsExp()) experimentId = 3;
        url.searchParams.set('exp', experimentId);
        window.location.replace(url.href);
    }
};

export {initHomepageExperiments};
