import {bootstrapGTM} from '@busuu/marketing';
import {ExceptionService, ThirdPartyService} from '@busuu/legacy-core';

/**
 * Google Tag Manager
 */
var GTMScript = (function (root, doc, undefined) {

    var logError = function (message, data) {
        ExceptionService.handle('warning', { filename, message, data });
    };

    var isVendorDisabled = function () {
        return ThirdPartyService.isVendorDisabled();
    };

    var loadGTM = function () {

        var gtmId = doc.body.getAttribute('data-gtm-id');
        if (!gtmId) {
            logError('data-gtm-id attribute not found');
            return;
        }

        bootstrapGTM(gtmId);
    };

    var load = function () {
        if (isVendorDisabled()) {
            return false;
        }

        root.dataLayer = root.dataLayer || [];
        root.addEventListener('load', loadGTM);

    };

    /**
     * Private vars
     */
    var filename = 'script-gtm.js';

    return {
        load: load
    };

})(window, document);

export default GTMScript;
