import {Utils} from '@busuu/legacy-core';

/**
 * Returns the plan duration from a nav item
 * @param {Element} element
 * @return {Number}
 */
const getPlanDuration = (element) => {
    const months = element.getAttribute('data-month-plan');
    return Number(months);
};

/**
 * @return {Array}
 */
const getNavigationItems = () => {
    const tieredPlansNav = Utils.getById('premium-paywall-nav');
    return Utils.getElementsByClass('js-navglide-item', tieredPlansNav);
};

const PremiumPaywallHelper = {
    getPlanDuration,
    getNavigationItems,
};

export default PremiumPaywallHelper;
