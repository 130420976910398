import {getStandardPricingCohort} from '@busuu/experiments';
import {retrieveEcommerceOriginValue} from '@busuu/marketing';
import {Utils, ExceptionService} from '@busuu/legacy-core';
import Tracking from 'tracking/tracking-controller.ts';
import CurrentUserService from 'user/current-user-service.js';
import PremiumPaywallHelper from 'paywalls/premium/premium-paywall-helper.js';
import PremiumPaywallUIService from 'paywalls/premium/premium-paywall-ui-service.js';
import FreeTrialBanner from 'free-trial/free-trial-banner/free-trial-banner-controller.js';
import {getPricing} from 'pricing/pricing-service';
import {userIsEligibleForReferralFreeTrial} from 'free-trial/free-trial.service';
import {getV3PaywallUrl, V3_PAYWALL_PLANS_PAGE} from 'redirects/redirects-service.ts';

// Vars
const FILENAME = 'premium-paywall-controller.js';
const STANDARD_PRICING_COHORT = getStandardPricingCohort();
let tieredPlans;
let tieredPlansCasual;
let tieredPlansSerious;
let plansData = {};
let currentUser = null;
let isEligibleForReferralFreeTrial = false;

/**
 * We need a pointer to know which plan has been
 * selected and we store both tiers here.
 */
let activePlans = {
    casual: null,
    serious: null,
};

const onCodeFailure = (message = '', data = {}, type = 'error') => {
    ExceptionService.handle(type, {filename: FILENAME, message, data});
};

/**
 * @param {Element} element
 */
const getPlanDuration = (element) => {
    return PremiumPaywallHelper.getPlanDuration(element);
};

/**
 * @param {Number} duration
 */
const sendTracking = (duration) => {
    const discountMax = [activePlans.casual, activePlans.serious].reduce((a, b) => {
        return Math.max(a.discount_percent || 0, b.discount_percent || 0);
    });
    const data = {
        plan_length: duration,
        discount_amount: discountMax,
    };
    const origin = retrieveEcommerceOriginValue();
    if (origin) {
        data.ecommerce_origin = origin;
    }
    Tracking.send('PAYWALL_PLAN_DURATION_CHANGED', data);
};

/**
 * @param {Number} monthPlan
 */
const setActivePlan = (monthPlan) => {
    // Find all plans that match `monthPlan` duration
    const plans = plansData.map((product) => product.prices.find((price) => price.interval_count === monthPlan));

    activePlans = {
        casual: plans.find((plan) => plan.product_id === 'premium_standard'),
        serious: plans.find((plan) => plan.product_id === 'premium_plus'),
    };

    // Defines the tier element for the prices
    const tieredPlanElements = {
        casual: tieredPlansCasual,
        serious: tieredPlansSerious,
    };

    // ..then display them
    Object.keys(activePlans).forEach((key) => {
        tieredPlanElements[key].forEach((element) => {
            PremiumPaywallUIService.updatePlanDetails({
                plan: activePlans[key],
                element,
                cohort: STANDARD_PRICING_COHORT,
                planType: key,
                authenticated: !!currentUser,
            });
        });
    });
};

/**
 * On click of a nav-glide element
 * @param {Element} element
 */
const onItemClick = (element) => {
    const duration = getPlanDuration(element);
    setActivePlan(duration);
    sendTracking(duration);
};

/**
 * Compare the nav slider to the actual plans
 * that we have after filtering (pricing-service)
 */
const removeExtraPlansFromNav = () => {
    PremiumPaywallUIService.removeExtraPlansFromNavigation(plansData);
};

const setupTogglerAndDisplayActivePlan = () => {
    // Init navigation and get active element
    const navGlideInstance = PremiumPaywallUIService.initNav({
        onItemClick,
    });

    // Display selected plan
    const activeItem = navGlideInstance.getActiveItem();
    setActivePlan(getPlanDuration(activeItem));
};

const redirect = (URL) => {
    window.location.href = URL;
};

const onRequestStart = () => {
    PremiumPaywallUIService.setState('REQUEST_SUCCESS');
};

const onPlanSelection = function (e) {
    e.preventDefault();
    onRequestStart();

    redirect(this.href);
};

/**
 * If the user is authenticated,
 * we need a click handler on each plans to
 * add severals steps before going to the cart.
 */
const updateButtonBehaviours = () => {
    Utils.delegate(tieredPlans, 'js-submit-plan', 'click', onPlanSelection);
};

const onPlansError = (e) => {
    onCodeFailure('Tiered plans loading error', e, 'warning');
    PremiumPaywallUIService.setState('ERROR', 'TEMPORARY_ERROR');
};

const paywallCustomizedByLanguageLearnt = async () => {
    try {
        const data = CurrentUserService.get();
        PremiumPaywallUIService.paywallCustomizedByLanguageLearnt(data.languages);
    } catch (err) {
        // Ignore error
    }
};

/**
 * Fetches pricing and sets up UI
 */
const fetchPricing = async () => {
    plansData = await getPricing(STANDARD_PRICING_COHORT);
    removeExtraPlansFromNav();
    onRequestStart();
    setupTogglerAndDisplayActivePlan();
    updateButtonBehaviours();
    paywallCustomizedByLanguageLearnt();
    PremiumPaywallUIService.setState('REQUEST_COMPLETE');
};

const init = async () => {
    try {
        isEligibleForReferralFreeTrial = await userIsEligibleForReferralFreeTrial();
    } catch (e) {
        isEligibleForReferralFreeTrial = false;
    }
    // Not ideal from an SEO perspective but temp solution until 3.0 paywall is SEO optimised
    // Paywall is modular so exists on other pages e.g. home page
    // We only want to redirect users who land on the main paywall page
    const isOnPaywallPage = /busuu\.com\/(?:[a-z]{2}(?:-[a-z]{2})?\/)?premium(?:\?.*)?$/.test(window.location.href);

    if (isOnPaywallPage) {
        window.location.replace(getV3PaywallUrl(V3_PAYWALL_PLANS_PAGE));
    }

    tieredPlans = Utils.getById('premium-paywall');
    tieredPlansCasual = Utils.getElementsByClass('js-premium-paywall-casual');
    tieredPlansSerious = Utils.getElementsByClass('js-premium-paywall-serious');

    if (!tieredPlans) {
        return false;
    }

    PremiumPaywallUIService.setState('REQUEST_START');
    /**
     * Non discounted savings experiment requires no free trial to be shown
     * Referral users are excluded from experiment
     */
    if (isEligibleForReferralFreeTrial) {
        FreeTrialBanner.init();
    }

    try {
        /**
         * We auth to see if the CTAs should direct to /login or /purchase
         * NB: We *have* to force fetch the user here for autoauth
         */
        try {
            currentUser = await CurrentUserService.get({force: true});
            fetchPricing();
        } catch (e) {
            /**
             * If the user call fails, we still proceed to fetch the pricing un-authed
             */
            fetchPricing();
        }
    } catch (e) {
        /**
         * If all fails, show error
         */
        onPlansError();
    }
};

export {init};
