/**
 * Common Scripts
 */
import 'common/common-scripts.js';

/**
 * Modular Specific Scripts
 */
import 'components/sliders-init.js';
import 'components/video-loader.js';
import 'components/discount-banner.js';
import 'components/tab-carousel.js';
import 'modules/choose-your-language.js';
import 'paywalls/premium/premium-header-controller.js';
import 'third-party/script-trustpilot.js';
import 'third-party/script-zendesk.js';
import 'modules/fade-in.js';
import 'modules/faq-module.js';
import 'modules/referral-header.js';
import 'tracking/pages/tracking-pages.js';
import 'tracking/pages/tracking-premium.js';
import 'tracking/pages/tracking-claim-trial.js';
import 'tracking/pages/tracking-edu.js';
import 'components/app-links.js';
import 'modules/months-free-landing-header.js';
import 'modules/main-header-wave-and-revamp.js';
import {initHomepageExperiments} from 'modules/main-landing-page-experiments.js';
import 'modules/app-preview.js';
import 'modules/reviews.js';
import 'modules/free-trial-benefits.js';
import 'voucher/voucher-controller.js';
import 'discount/discount-controller.js';
import 'modules/student-discount.js';
import {init as initPremiumPaywall} from 'paywalls/premium/premium-paywall-controller.js';
import {initStickyPromoBanner} from 'modules/sticky-promo-banner.js';

// Init the premium paywall
initPremiumPaywall();

// Initialise sticky promo banner
initStickyPromoBanner();

// init experiments engine
initHomepageExperiments();
