/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Utils, ExceptionService } from '@busuu/legacy-core';
import currentExperiments from 'experiments/experiments-service.constants';
/**
 *
 * We are using Optimizely X for A/B Testing Experiments.
 *
 * Optimizely will sort user and choose in which group (called bucket) a user will be.
 * Most of the time there is two buckets:
 * 1) Original: the website as usual, it's the control group (the one to compare to)
 * 2) Variation: The modifed experience, this is the experiment version
 * The bucket affectation is all done via optimizely though the field
 * Audiences for the rules (plateform, ip, country, interface languages...)
 * and Traffic Allocation for the percentage split.
 *
 * NB: Optimizely data is stored in your cookies & localstorage so don't forget
 * to clear BOTH to get a new bucket affectation or use incognito mode.
 *
 * For optimizely reference look at the api doc
 * https://developers.optimizely.com/x/solutions/javascript/reference/index.html#functions
 *
 * Some particularly useful ones are :
 * - window.optimizely.get('state') : gives you a list of usefull functions
 * - window.optimizely.get('state').getVariationMap() : gives the variations for all active experiments
 * - window.optimizely.get('state').getExperimentStates() : gives info about experiment
 * - window.optimizely.get('data') : lists lots of optimizely config info
 * - window.optimizely.get('visitor') : information about the current user in optimizely
 *
 */
/**
 * Private vars
 */
const filename = 'experiments-service.ts';
let runningExperiments = null;
const logError = (type, message, data) => {
    ExceptionService.handle(type, {
        filename,
        message,
        data,
        // TODO: Make sendToSentry optional in JSDoc comment in core
        sendToSentry: type === 'error',
    });
};
const isOptimizelyPresent = () => {
    if (window.optimizely && window.optimizely.get) {
        return true;
    }
    logError('warning', "Optimizely hasn't been found on the window.");
    return false;
};
/**
 * Returns an array with all the current
 * running experiments.
 * NB: We avoid mutliple invokes to getExperimentStates() method
 * in Optimizely as it has costs performance (see documentation).
 */
const getRunningExperiments = () => {
    if (!isOptimizelyPresent()) {
        return {};
    }
    if (runningExperiments) {
        return runningExperiments;
    }
    runningExperiments = window.optimizely.get('state').getExperimentStates({
        isActive: true,
    });
    return runningExperiments;
};
/**
 * Returns a given experiment, based on ID.
 */
const getExperiment = (id) => {
    return getRunningExperiments()[id] || null;
};
/**
 * Returns the current variation from a given experiment.
 */
const getExperimentVariationId = (experiment) => {
    if (!experiment) {
        return null;
    }
    return experiment.variation ? experiment.variation.id : null;
};
/**
 * Returns a formatted Object based on a given experiment ID
 * and the variations ids of that experiment.
 */
const getExperimentData = (experimentId, variations) => {
    const experiment = getExperiment(experimentId);
    const currentVariationId = getExperimentVariationId(experiment);
    if (!experiment || !(currentVariationId in variations)) {
        return null;
    }
    return {
        experimentId: experimentId,
        variationId: currentVariationId,
        bucket: variations[currentVariationId],
    };
};
/**
 * Returns data from a given experiment.
 */
const get = (experimentType) => {
    const experiment = currentExperiments[experimentType];
    if (experiment) {
        return getExperimentData(experiment.experimentId, experiment.variations);
    }
    logError('error', 'experimentType: ' + experimentType + ' not found');
    return null;
};
/**
 * Checks if the user is in the given experiment and in the given bucket
 *
 * NB: `data` can be:
 * - a bucket string like 'B' if we want to check specifically against a certain bucket
 * - an array like ['B', 'C'] if we want to check they're in any of x buckets
 */
const isInExperimentBucket = (experimentName, bucket) => {
    const experiment = get(experimentName);
    // We can match against multiple buckets
    if (Utils.isArray(bucket)) {
        return experiment && bucket.includes(experiment.bucket);
    }
    return experiment && experiment.bucket === bucket;
};
const ExperimentsService = {
    get,
    isInExperimentBucket,
};
export default ExperimentsService;
