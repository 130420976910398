export const DELAYED_REGISTRATION = 'DELAYED_REGISTRATION';
export const DELAYED_REGISTRATION_ORIGINAL = 'A';
export const DELAYED_REGISTRATION_VARIATION_1 = 'B';
export const REGISTRATION_PAGE_COPY = 'REGISTRATION_PAGE_COPY';
export const REGISTRATION_PAGE_COPY_ORIGINAL = 'A';
export const REGISTRATION_PAGE_COPY_VARIATION = 'B';
export const FLAGS_SECTION = 'FLAGS_SECTION';
export const FLAGS_SECTION_ORIGINAL = 'A';
export const FLAGS_SECTION_VARIATION = 'B';
export const HOW_IT_WORKS_SECTION = 'HOW_IT_WORKS_SECTION';
export const HOW_IT_WORKS_SECTION_ORIGINAL = 'A';
export const HOW_IT_WORKS_SECTION_VARIATION = 'B';
const experiments = {
    [DELAYED_REGISTRATION]: {
        experimentId: '24076350301',
        variations: {
            24080340431: DELAYED_REGISTRATION_ORIGINAL,
            24133250039: DELAYED_REGISTRATION_VARIATION_1,
        },
    },
    [REGISTRATION_PAGE_COPY]: {
        experimentId: '23495680644',
        variations: {
            23486590774: REGISTRATION_PAGE_COPY_ORIGINAL,
            23505700626: REGISTRATION_PAGE_COPY_VARIATION,
        },
    },
    [HOW_IT_WORKS_SECTION]: {
        experimentId: '24598610282',
        variations: {
            24630080054: HOW_IT_WORKS_SECTION_ORIGINAL,
            24559951843: HOW_IT_WORKS_SECTION_VARIATION,
        },
    },
    [FLAGS_SECTION]: {
        experimentId: '24671230302',
        variations: {
            24648380397: FLAGS_SECTION_ORIGINAL,
            24658830045: FLAGS_SECTION_VARIATION,
        },
    },
};
export default experiments;
