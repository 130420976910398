import {Utils} from '@busuu/legacy-core';
import CourseConfigService from 'course-config/course-config-service.js';
import TranslationsService from 'common/translations.service.ts';
import Tracking from 'tracking/tracking-controller.ts';
import {ALL_COURSE_LANGS_CODES} from '@busuu/languages';
import FlagsListClass from 'components/flags-list-class.ts';
import {DYNAMIC_FLAGS_READY_EVENT} from 'components/flags-list-constants.js';
import PubSub from 'pubsub-js/src/pubsub.js';
import {isInFlagsExp} from 'experiments/flags-experiment.js';

let loaderElement = null;
let flagsContainerElement = null;

/**
 * Fetches the course languages available for the current interface.
 * Fallback to a full list of languages if the call fails.
 */
const populateFlags = async () => {
    let languages = null;

    try {
        languages = await CourseConfigService.getAvailableCourseLanguages();
    } catch (e) {
        languages = ALL_COURSE_LANGS_CODES;
    }

    new FlagsListClass({
        languages,
        loader: loaderElement,
        container: flagsContainerElement,
    });
};

const flagClickHandler = (flag, event) => {
    if (event) {
        event.preventDefault();
    }

    const targetValue = flag.getAttribute('data-value');

    if (targetValue) {
        Tracking.sendAndWait('COURSE_SELECTED', {
            language_selected: targetValue,
            source_page: 'onboarding',
            course: 'none - web onboarding',
        });
    }

    window.location.href = flag.href;
};

// Add tracking for all existing flags
const addFlagsTracking = () => {
    const allFlags = Utils.getElementsByClass('js-flag');
    if (allFlags.length) {
        allFlags.forEach((flag) => flag.addEventListener('click', flagClickHandler.bind(this, flag)));
    }
};

const addLearnersCount = () => {
    const flags = Utils.getElementsByClass('flag-text', flagsContainerElement);
    const millions = TranslationsService.getTranslation('X_M_LEARNERS');
    const thousands = TranslationsService.getTranslation('X_K_LEARNERS');

    // If the user lang is one of this, we will make a transformation to the numerical value
    const mutateCountInLangs = ['ja', 'zh'];
    const userInterfaceLang = window.location.pathname.split('/')[1] || 'en';

    const mutate = mutateCountInLangs.includes(userInterfaceLang);

    /**
     * Returns translation for "X thousands of learners"
     * In the languages indicated in the mutatedCountInLangs
     * instead of indicating 700K, 70 groups of 10K are indicated.
     * 700K -> 70 10K -> ( 70 * 10000 = 700000)
     */
    const getThousandsLearners = (count) => {
        const learnersNumber = mutate ? count / 10 : count;
        return TranslationsService.replaceTranslation(thousands, learnersNumber);
    };

    /**
     * Returns translation for "X Millions of users"
     * In the languages indicated in the mutatedCountInLangs
     * instead of indicating 7M, 700 groups of 10K are indicated.
     * 7M -> 700 10K ( 700 * 100000 = 7000000)
     */
    const getMillionsLearners = (count) => {
        const learnersNumber = mutate ? count * 100 : count;
        return TranslationsService.replaceTranslation(millions, learnersNumber);
    };

    const learnersNumber = {
        ar: getMillionsLearners(1),
        de: getMillionsLearners(4),
        en: getMillionsLearners(26),
        es: getMillionsLearners(5),
        fr: getMillionsLearners(5),
        it: getMillionsLearners(2),
        ja: getMillionsLearners(4),
        nl: getThousandsLearners(30),
        pl: getThousandsLearners(500),
        pt: getThousandsLearners(800),
        ru: getMillionsLearners(2),
        tr: getMillionsLearners(1),
        zh: getThousandsLearners(900),
        ko: getThousandsLearners(100),
    };

    flags.forEach((flag) => {
        const lang = flag.getAttribute('data-value');

        const learnersParagraph = document.createElement('p');
        Utils.addClass(learnersParagraph, 'flag-text__learners');

        const learnersParagraphContent = document.createTextNode(`${learnersNumber[lang]}`);
        learnersParagraph.appendChild(learnersParagraphContent);

        const chevron = Utils.getElementByClass('flag-text__chevron', flag);
        flag.insertBefore(learnersParagraph, chevron);
    });
};

const init = () => {
    loaderElement = Utils.getById('flags-list-dynamic-loader');
    flagsContainerElement = Utils.getById('flags-list-dynamic-container');

    const languageSelectorWrapper = Utils.getById('js-choose-language');
    const isInChooseLanguagePage = Utils.getById('choose-your-language-pixel');

    // Load and display the flags
    if (loaderElement && flagsContainerElement) {
        populateFlags();
    }

    PubSub.subscribe(DYNAMIC_FLAGS_READY_EVENT, function () {
        if ((languageSelectorWrapper && isInChooseLanguagePage) || (languageSelectorWrapper && isInFlagsExp())) {
            addLearnersCount();
        }

        // Dynamic flags. Add tracking.
        addFlagsTracking();
    });

    // Static Flags. Add tracking.
    addFlagsTracking();
};

init();
