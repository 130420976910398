import {Utils, ExceptionService} from '@busuu/legacy-core';
import {findCheapestAvailablePrice} from '@busuu/pricing';
import {getPricing} from 'pricing/pricing-service';
import {getStandardPricingCohort} from '@busuu/experiments';

// Private vars
const FILENAME = 'premium-header-controller.js';
const headerPriceWrap = Utils.getById('premium-header-price-wrap');
const headerPrice = Utils.getById('premium-header-price');
const headerPriceWrapClass = 'main-header__price--hide';
const headerPriceStrikeClass = 'main-header__price--strike';
const headerPriceBigClass = 'main-header__price-impact';
let plansData = null;

const onCodeFailure = (error) => {
    ExceptionService.handle('error', {
        filename: FILENAME,
        data: error,
    });
};

const getTranslation = (key) => {
    return window.BUSUU_TRANSLATIONS[key] || 'TRS_NOT_FOUND';
};

const showHeaderPrice = () => {
    Utils.removeClass(headerPriceWrap, headerPriceWrapClass);
};

/**
 * Returns a constructed header string of 'From $x'
 * @param {Object} plan details of plan
 * @return {String}
 */
const getHeaderPriceString = (plan) => {
    const priceText = getTranslation('FROM_PRICE');
    let priceHTML = '';
    let finalPrice = plan.amount_human_readable;

    // If discounted, display original price and add strike through
    if (plan.discount_percent) {
        finalPrice = plan.amount_after_discount_human_readable;
        const originalPrice = plan.amount_human_readable;
        priceHTML = `<span class="${headerPriceStrikeClass}">${originalPrice}</span>`;
    }

    priceHTML += `<span class="${headerPriceBigClass}">${finalPrice}</span>`;

    return priceText.replace('%s', priceHTML);
};

/**
 * Inject the correct string into the header div
 * Call showHeaderPrice to fade in div
 * @param {Object} plan data
 */
const appendHeaderPrice = (plan) => {
    const headerPriceString = getHeaderPriceString(plan);
    if (headerPriceString) {
        headerPrice.innerHTML = headerPriceString;
        showHeaderPrice();
    }
};

/**
 * On API call succes, we want to do 2 things:
 * - Filter the plans to keep the cheapest
 * - Append the correct string to the header
 * @param {Object} API response
 */
const getCheapestPrice = (response) => {
    const cheapestPrice = findCheapestAvailablePrice(response);
    if (cheapestPrice) {
        appendHeaderPrice(cheapestPrice);
    } else {
        onCodeFailure({
            reason: "Can't find cheapest plan",
            plans: response,
        });
    }
};

const init = async () => {
    if (!headerPriceWrap || !headerPrice) {
        return false;
    }

    try {
        const cohort = getStandardPricingCohort();
        plansData = await getPricing(cohort);
        getCheapestPrice(plansData);
    } catch (e) {
        onCodeFailure({
            reason: "Couldn't fetch plans",
        });
    }
};

init();
