import {Utils, ExceptionService} from '@busuu/legacy-core';
import GTMScript from 'third-party/script-gtm.js';
import BreakPointDetection from 'helpers/breakpoint-detection.js';
import Optimizely from 'tracking/tracking-optimizely.js';

/**
 * Private vars
 */
const filename = 'tracking-gtm.js';
let enableLogs = false;
let commonData = {}; // basic data send through all tracking

/**
 * GTM Manager
 */
const getDevice = function () {
    return BreakPointDetection.getState() >= 3 ? 'web' : 'mobile';
};

/**
 * Checks if GTM is present on window object
 * @return {Boolean}
 */
const isGTMPresent = function () {
    if (!window.dataLayer) {
        ExceptionService.handle('warning', {
            filename,
            message: 'GTM is not found on the window.',
        });
    }
    return !!window.dataLayer;
};

/**
 * Prevents tracking if user is on a localhost environment.
 * @return {Boolean}
 */
const preventTracking = function (eventName, opts) {
    if (enableLogs) {
        ExceptionService.handle('warning', {
            filename,
            message: `TRACKING GTM: ${eventName}`,
            data: opts,
        });

        return false;
    }

    return Utils.isLocalEnvironment();
};

const push = function (params) {
    window.dataLayer.push(params);
};

const sendEvent = function (eventTemplate = {}, data = {}) {
    const eventName = eventTemplate.real_event_name;

    if (!eventName) {
        ExceptionService.handle('error', {
            filename,
            message: 'sendEvent() - eventName is missing',
        });
        return false;
    }

    // Always send through commonData + options
    let params = {
        ...commonData,
        ...data,
        event: eventName,
    };
    params = Utils.deepClone(params);

    if (!isGTMPresent() || preventTracking(eventName, params)) {
        return false;
    }

    push(params);
};

/**
 * Add additional datas to the tracking when the user
 * is authenticated
 */
const identifyUser = function (user) {
    commonData = {
        ...commonData,
        role: user.is_premium ? 'premium' : 'free',
        customerId: user.uid,
        countryCode: user.country_code,
        customerDevice: getDevice(),
        languageInterface: user.languages ? user.languages.interface : null,
        languageLearned: user.languages ? user.languages.learning_default : null,
        registrationDate: new Date(user.created * 1000).toString(),
        hashedUserEmail: user.hashed_email,
    };
};

const load = function (params) {
    if (params.logs) {
        enableLogs = true;
    }

    /**
     * Additional information if experiments data exists
     */
    if (Optimizely.isLoaded()) {
        commonData.experiments = Optimizely.getExperimentsData();
    }

    GTMScript.load();
};

const GTM = {
    sendEvent,
    identifyUser,
    load,
};

export default GTM;
