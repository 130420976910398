import {Utils} from '@busuu/legacy-core';
import Tracking from 'tracking/tracking-controller.ts';
import ReferralService from 'referral/referral-service.js';
import TranslationsService from 'common/translations.service.ts';
import {findFreeTrialPlan} from '@busuu/pricing';
import {getPricing} from 'pricing/pricing-service';
import {getFTReferralPricingCohort} from '@busuu/experiments';

// Private vars
const PRICING_COHORT = getFTReferralPricingCohort();
const BUBBLE_VISIBLE_CLASS = 'referral-header__bubble--visible';
let loadingSection = null;
let fullSection = null;
let referralCode = null;

const showSection = (section) => {
    Utils.removeClass(section, 'hidden');
};

const hideSection = (section) => {
    Utils.addClass(section, 'hidden');
};

/**
 * Switches the view from the loading section to the relevant full section
 */
const showLoadedSection = () => {
    hideSection(loadingSection);
    showSection(fullSection);
};

/**
 * Replaces the image source to populate the avatar element
 * @param {String} avatar URL
 */
const populateAvatar = (avatar) => {
    const avatarElement = Utils.getById('referral-avatar-wrapper');
    const avatarImageElement = Utils.getById('referral-avatar-image');

    if (avatar) {
        avatarImageElement.src = avatar;
    }

    Utils.removeClass(avatarElement, 'hidden');
};

/**
 * Populates the user's name
 * @param {String} name
 */
const populateName = (name) => {
    const nameElement = Utils.getById('referral-name');

    Utils.setText(nameElement, name);
};

/**
 * Replaces the title text
 */
const populateTitle = (freeTrialDays) => {
    const titleElement = Utils.getById('referral-title');
    const translation = TranslationsService.getAndReplaceTranslationMulti('HERES_X_DAY_TRIAL_LETS_LEARN_TOGETHER', [
        freeTrialDays,
    ]);

    Utils.setText(titleElement, translation);
};

/**
 * Replaces the subtitle text
 */
const populateSubtitle = () => {
    const subtitleElement = Utils.getById('referral-subtitle');
    const translation = TranslationsService.getTranslation('X_MILLION_PEOPLE_ARE_LEARNING_NOW_YOUR_TURN');

    Utils.setText(subtitleElement, translation);
};

/**
 * Sets up and populates the header
 * @param {String} name
 * @param {String} avatar
 * @param {number} freeTrialDays
 */
const populateHeader = ({name, avatar, freeTrialDays}) => {
    const bubbleElement = Utils.getById('referral-bubble');

    populateName(name);
    populateAvatar(avatar);
    populateTitle(freeTrialDays);
    populateSubtitle();

    Utils.addClass(bubbleElement, BUBBLE_VISIBLE_CLASS);
};

const fetchHeaderData = () => {
    const onSuccess = ([referralData, plansData]) => {
        const trialPlan = findFreeTrialPlan(plansData);

        if (trialPlan) {
            return {
                name: referralData.name,
                avatar: referralData.has_avatar ? referralData.avatar_variations.small : '',
                freeTrialDays: trialPlan.free_trial_duration_days,
            };
        }

        throw new Error('No free trial available');
    };

    const headerDataPromises = [ReferralService.getReferralData(referralCode), getPricing(PRICING_COHORT)];

    return Promise.all(headerDataPromises).then(onSuccess);
};

const loadHeader = () => {
    const onComplete = () => {
        // Added delay to ensure loading state is properly visible
        setTimeout(showLoadedSection, 1500);
    };

    return fetchHeaderData()
        .then(populateHeader)
        .catch(() => {})
        .then(onComplete);
};

const redirect = (url) => {
    window.location.href = url;
};

const getTrackingParams = () => {
    const params = {
        screen_name: 'share_invite',
    };

    if (referralCode) {
        params.referral_token = referralCode;
    }

    return params;
};

/**
 * Sends tracking and redirects to the next step.
 * @param {Object} click event
 */
const onCtaClick = function (event) {
    if (event) {
        event.preventDefault();
    }

    const params = getTrackingParams();

    Tracking.sendAndWait('REFERRAL_LANDING_PAGE_CTA_SELECTED', params).then(redirect.bind(null, this.href));
};

const init = () => {
    loadingSection = Utils.getById('referral-loading-section');
    fullSection = Utils.getById('referral-full-section');

    if (loadingSection && fullSection) {
        const cta = Utils.getById('referral-cta');
        cta.addEventListener('click', onCtaClick);

        ReferralService.storeReferralUserCode();
        referralCode = ReferralService.getReferralUserCode();

        Tracking.send('REFERRAL_LANDING_PAGE_VIEWED', getTrackingParams());

        loadHeader();
    }
};

init();

export {init};
