import CurrentUser from 'user/current-user-service.js';
import {storeEcommerceOriginValue, retrieveEcommerceOriginValue, ECOMMERCE_ORIGIN_CONSTANTS} from '@busuu/marketing';
import {ExceptionService} from '@busuu/legacy-core';
import WindowLocationService from 'common/window-location.service.js';
import FreeTrialBannerReferral from 'free-trial/free-trial-banner/free-trial-banner-referral.js';
import FreeTrialBannerBasic from 'free-trial/free-trial-banner/free-trial-banner-basic.js';
import {getPricing} from 'pricing/pricing-service';
import {findFreeTrialPlan, buildCheckoutRequiredParams, FREE_TRIAL_TYPES, getFreeTrialType} from '@busuu/pricing';
import {getFreeTrialPricingCohort} from '@busuu/experiments';
import {getCheckoutUrl} from 'redirects/redirects-service';

// Private vars
const FILENAME = 'free-trial-banner-controller.js';
let pricingCohort = null;
let currentUser = null;
let trialType = null;
let freeTrialPlan = null;

const onError = (error) => {
    ExceptionService.handle('warning', {
        filename: FILENAME,
        message: 'Tiered plans could not be loaded for free claim trial banner',
        data: error,
    });
};

const getTrialType = async (user) => {
    const baseUrl = process.env.baseAPI;
    const type = await getFreeTrialType(baseUrl, user);

    if (!type) {
        throw {
            reason: 'UNIDENTIFIED_TRIAL_TYPE',
        };
    }

    return type;
};

const onTrialSelection = (ecommerceOrigin) => {
    const params = buildCheckoutRequiredParams(freeTrialPlan.id, pricingCohort);
    const href = getCheckoutUrl(params);
    storeEcommerceOriginValue(ecommerceOrigin);
    WindowLocationService.redirect(href);
};

const loadReferralBanner = (freeTrialDays) => {
    new FreeTrialBannerReferral(freeTrialDays, {
        onClickCTA: onTrialSelection.bind(this, 'CLAIM_REFERRAL_FT_PAYWALL_BANNER'),
    });
};

const loadBasicBanner = (freeTrialDays, ecommerceOrigin) => {
    new FreeTrialBannerBasic(freeTrialDays, {
        onClickCTA: onTrialSelection.bind(this, ecommerceOrigin),
    });
};

const loadFreeTrialBanner = (type) => {
    const {free_trial_duration_days: freeTrialDays} = freeTrialPlan;
    if (retrieveEcommerceOriginValue() === ECOMMERCE_ORIGIN_CONSTANTS.FRICTION_WITH_ADS) {
        return loadBasicBanner(freeTrialDays, 'FRICTION_WITH_ADS');
    }

    if (type === FREE_TRIAL_TYPES.BASIC) {
        return loadBasicBanner(freeTrialDays, 'CLAIM_BASIC_FT_PAYWALL_BANNER');
    }

    if (type === FREE_TRIAL_TYPES.REFERRAL) {
        return loadReferralBanner(freeTrialDays);
    }
};

const init = async () => {
    try {
        // Fetch the user and the pricing cohort (user is required here to proceed, otherwise .catch())
        currentUser = await CurrentUser.get();
        trialType = await getTrialType(currentUser);

        // Get cohort and products
        pricingCohort = getFreeTrialPricingCohort(trialType);
        const products = await getPricing(pricingCohort);

        // Get Free trial plan
        freeTrialPlan = findFreeTrialPlan(products);

        if (!freeTrialPlan || !freeTrialPlan.free_trial_duration) {
            throw {reason: 'NO_FREE_TRIAL_PLAN'};
        }

        // Finally load the correct banner
        loadFreeTrialBanner(trialType);
    } catch (e) {
        onError(e);
    }
};

const FreeTrialBanner = {
    init,
};

export default FreeTrialBanner;
