import RunningExperimentsService from 'experiments/experiments-service.ts';
import {DELAYED_REGISTRATION} from 'experiments/experiments-service.constants.ts';

const isInExperiment = () => {
    return RunningExperimentsService.isInExperimentBucket(DELAYED_REGISTRATION, 'B');
};

const DelayedRegistrationExperiment = {
    isInExperiment,
};

export default DelayedRegistrationExperiment;
