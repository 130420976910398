import PromotionService from 'promotion/promotion-service.js';
import {Utils} from '@busuu/legacy-core';
import {getPricing} from 'pricing/pricing-service';
import {findPlanByTierAndDuration, buildCheckoutRequiredParams} from '@busuu/pricing';
import TranslationsService from 'common/translations.service.ts';
import {getStandardPricingCohort} from '@busuu/experiments';

// Elements
let subTitleEl = null;
let ctaEl = null;

const HIDDEN_CLASS = 'hidden';
const PRICING_COHORT = getStandardPricingCohort();
const onClickBuyNow = function (planId, e) {
    if (e) {
        e.preventDefault();
    }

    const params = buildCheckoutRequiredParams(planId, PRICING_COHORT);

    window.location.href = `${this.href}&${params}`;
};

const setupLandingHeader = (plan, planDuration) => {
    const wasPrice = plan.amount_human_readable;
    const nowPrice = `<span>${plan.amount_after_discount_human_readable}</span>`;
    const subTitle = TranslationsService.getAndReplaceTranslationMulti('X_MONTHS_OF_PREMIUM_PLUS_WAS_X_NOW_X', [
        planDuration,
        wasPrice,
        nowPrice,
    ]);
    subTitleEl.innerHTML = subTitle;
    ctaEl.addEventListener('click', onClickBuyNow.bind(ctaEl, plan.id));
};

const canShowPromotionLanding = (discountPercent, planDuration) => {
    return PromotionService.canShowBuyXGetXFree(discountPercent, planDuration);
};

const hideSkeletonLoaders = (landingPlaceholder, landingContent) => {
    Utils.addClass(landingPlaceholder, HIDDEN_CLASS);
    Utils.removeClass(landingContent, HIDDEN_CLASS);
};

const fetchLandingPromotion = (planDuration) => {
    const landingPlaceholder = Utils.getById('months-free-landing-placeholder');
    const landingContent = Utils.getById('months-free-landing-content');

    const onSuccess = (plans) => {
        const plan = findPlanByTierAndDuration(plans, planDuration, 'premium_plus');

        if (plan) {
            const isPromotionLandingValid = canShowPromotionLanding(plan.discount_percent, planDuration);

            if (isPromotionLandingValid) {
                setupLandingHeader(plan, planDuration);
            }
        }
    };

    getPricing(PRICING_COHORT)
        .then(onSuccess)
        .catch(() => {})
        .then(hideSkeletonLoaders.bind(null, landingPlaceholder, landingContent));
};

const init = () => {
    const header = Utils.getById('months-free-landing-header');

    if (header) {
        const planDuration = Number(header.getAttribute('data-plan-duration') || 12);

        subTitleEl = Utils.getElementByClass('months-free-landing-header__subtitle', header);
        ctaEl = Utils.getElementByClass('months-free-landing-header__cta', header);

        fetchLandingPromotion(planDuration);
    }
};

init();

export {init};
